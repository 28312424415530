import { ArrowDownIcon } from '@components/UI/Icons/arrows'
import { Typography } from '@mui/material'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import { ChangeEvent, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import {
  StyledBottomFooter,
  StyledBottomFooterAddress,
  StyledBottomFooterInner,
  StyledItemContent,
  StyledFooterAccordion,
  StyledFooterAccordionSummary,
  StyledMobileOrTabletWrapper,
  StyledDesktopWrapper,
  StyledVersionInfo,
  StyledWebId,
  StyledCopyRightWrapper,
} from './BottomFooter.style'
import { GetCountriesLinks } from './components/CountriesLinks'
import { GetSocialLinks } from './components/SocialLinks'
import Container from '@components/UI/Container'
import Log from '@services/Log'
import { IPlacement } from '@typesApp/cmsPlacement/Placement'
import { ICMExternalPage } from '@typesApp/cmsPlacement/CMExternalPage'
import { EverythingElseLinks } from './components/EverythingElseLinks'
import HtmlRenderer from '@components/UI/HtmlRender'

import { useSelector } from 'react-redux'
import { forterWebIdSelector } from '@redux/selectors/forterWebId'
import { formatWebIdForDisplay } from '@utils/forter'
import { useBreakpoint } from '@hooks/useBreakpoint'

type BottomFooterProps = {
  addressComponent: ICMArticle
  everythingElseLinks: IPlacement<ICMExternalPage> | undefined
}

const BottomFooter = ({ addressComponent, everythingElseLinks }: BottomFooterProps) => {
  const { t } = useTranslation()
  const webId = useSelector(forterWebIdSelector)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const menuItemsAccordionDetailsRef = useRef<HTMLDivElement | null>(null)
  const versionNumber: string | undefined = process.env.NEXT_PUBLIC_RELEASE_VERSION
  const { isMobile, isTablet, isDesktop } = useBreakpoint()

  const handleChangeEvent = (_: ChangeEvent<{}>, expanded: boolean) => {
    setIsExpanded(v => !v)
    if (expanded) {
      menuItemsAccordionDetailsRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  // TODO: Replace dummy data with CoreMedia API call
  const socialLinks = GetSocialLinks()
  const countryLinks = GetCountriesLinks()

  const CountriesList = () => (
    <ul className="bottom-footer-list">
      {countryLinks.map(link => (
        <li key={link.label} className="bottom-footer-list__item">
          <a
            className={`bottom-footer-list__item-link ${
              link.isActive === 'true' ? 'bottom-footer-list__item-link--active' : ''
            }`}
            href={link.url}
            title={link.label}
          >
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  )

  const SocialList = () => (
    <ul className="bottom-footer-list bottom-footer-list--social">
      {socialLinks.map(social => (
        <li key={social.label} className="bottom-footer-list__item">
          <a href={social.url} target="_blank" rel="noreferrer" title={social.label}>
            {social.icon}
          </a>
        </li>
      ))}
    </ul>
  )

  if (!versionNumber) {
    Log.info('No version information present at build time.')
  }

  const copyRight = (
    <div className="bottom-footer-copyright">
      <Typography variant="caption">{t('Footer.Labels.Copyright', { year: new Date().getFullYear() })} </Typography>
      {versionNumber && <StyledVersionInfo variant="caption">{versionNumber}</StyledVersionInfo>}
    </div>
  )
  const renderWebId = (
    <StyledWebId>
      {webId ? `${t('PaymentMethodSelection.Labels.WebId')}: ${formatWebIdForDisplay(webId)}` : null}
    </StyledWebId>
  )

  const renderFooterAddress = (
    <div className="bottom-footer-container">
      <SocialList />
      {addressComponent && (
        <div className="bottom-footer-address">
          <HtmlRenderer htmlString={addressComponent?.detailText} variant="body2" />
        </div>
      )}
    </div>
  )

  const renderCopyRight = (
    <StyledCopyRightWrapper>
      {renderWebId}
      {copyRight}
    </StyledCopyRightWrapper>
  )

  return (
    <>
      <StyledMobileOrTabletWrapper>
        <Container>
          <div>
            <StyledFooterAccordion onChange={handleChangeEvent} expanded={isExpanded}>
              <StyledFooterAccordionSummary className="bottom-footer-header" expandIcon={<ArrowDownIcon />}>
                <span className="bottom-footer-title">{t('Footer.Labels.EverythingElse')}</span>
              </StyledFooterAccordionSummary>
              <StyledItemContent className="bottom-footer-content" ref={menuItemsAccordionDetailsRef}>
                <div className="bottom-footer-container">
                  <EverythingElseLinks links={everythingElseLinks?.items || []} />
                  <CountriesList />
                </div>
              </StyledItemContent>
            </StyledFooterAccordion>
          </div>
        </Container>
        <StyledBottomFooter>
          <StyledBottomFooterAddress>{renderFooterAddress}</StyledBottomFooterAddress>
        </StyledBottomFooter>
      </StyledMobileOrTabletWrapper>
      {(isMobile || isTablet) && renderCopyRight}

      <StyledDesktopWrapper>
        <Container>
          <StyledBottomFooterInner>
            <div>
              <div className="bottom-footer-container">
                <EverythingElseLinks links={everythingElseLinks?.items || []} />
                <CountriesList />
              </div>
              <div className="bottom-footer-container">
                <SocialList />
              </div>
            </div>
          </StyledBottomFooterInner>
        </Container>
      </StyledDesktopWrapper>
      {isDesktop && renderCopyRight}
    </>
  )
}

export default BottomFooter
