import { Accordion, AccordionSummary } from '@components/UI/Accordion/legacy'
import { Typography, styled } from '@mui/material'

export const StyledBottomFooter = styled('div', {
  name: 'BottomFooter',
})(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),

  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
    backgroundColor: theme.palette.background[3],
  },

  '.bottom-footer-header': {
    borderBottom: 0,
    paddingBottom: theme.spacing(4),
  },

  '.bottom-footer-title': {
    ...theme.typography.button,
    textTransform: 'uppercase',
  },

  '.bottom-footer-content': {
    padding: 0,
  },

  '.bottom-footer-container': {
    display: 'flex',
    flexDirection: 'column',
  },

  '.bottom-footer-list': {
    margin: 0,
    marginBottom: theme.spacing(5),
    padding: 0,
    display: 'grid',
    rowGap: theme.spacing(5),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
      display: 'flex',
      margin: theme.spacing(0, 0, 6),
      gap: theme.spacing(16),
    },

    a: {
      color: 'inherit',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        color: theme.palette.common.white,
      },
    },

    '&:last-of-type': {
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },

    '&--social': {
      alingItmes: 'center',
      display: 'flex',
      justifyContent: 'center',
      gap: theme.spacing(6),

      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
      },

      '.bottom-footer-list__item': {
        [theme.breakpoints.up('md')]: {
          margin: 0,
        },
      },
    },
  },

  '.bottom-footer-list__item': {
    margin: 0,
    listStyle: 'none',

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
    },

    'a > svg': {
      color: theme.palette.common.white,
    },
  },

  '.bottom-footer-list__item-link': {
    ...theme.typography.body2,
    textDecoration: 'none',
    '&--active > span': {
      fontWeight: theme.typography.fontWeightBold,
    },
    [theme.breakpoints.up('md')]: {
      color: theme.palette.common.white,
    },
  },

  '.bottom-footer-address': {
    div: {
      lineHeight: '20px',
      textAlign: 'center',
      p: {
        ...theme.typography.body2,
        color: theme.palette.common.white,
        margin: 0,
      },
      a: {
        display: 'none',
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  },

  '.bottom-footer-address__p': {
    margin: 0,
  },
}))

export const StyledBottomFooterAddress = styled('div', {
  name: 'BottomFooterAddress',
})(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(12, 0, 3),
}))

export const StyledBottomFooterInner = styled('div', {
  name: 'BottomFooterInner',
})(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(16, 0),
  },

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

export const StyledItemContent = styled('div', {
  name: 'BottomFooterItemContent',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(4, 0),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
  },
}))

export const StyledFooterAccordion = styled(Accordion, {
  name: 'BottomFooterAccordion',
})(() => ({}))

export const StyledFooterAccordionSummary = styled(AccordionSummary, {
  name: 'BottomFooterAccordionSummary',
})(({ theme }) => ({
  padding: theme.spacing(3, 0),
}))

export const StyledMobileOrTabletWrapper = styled(StyledBottomFooter, {
  name: 'BottomFooterMobileWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background[1],
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))

export const StyledDesktopWrapper = styled(StyledBottomFooter, {
  name: 'BottomFooterDesktopWrapper',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    backgroundColor: theme.palette.secondary.main,
    display: 'block',
  },
}))

export const StyledVersionInfo = styled(Typography, {
  name: 'VersionInfo',
})(() => ({
  fontStyle: 'italic',
}))

export const StyledWebId = styled(Typography, {
  name: 'WebId',
})(({ theme }) => ({
  ...theme.typography.caption,
  textAlign: 'center',

  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}))

export const StyledCopyRightWrapper = styled('div', {
  name: 'CopyRightWrapper',
})(({ theme }) => ({
  backgroundColor: theme.palette.background[1],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(6, 8),
  },

  [theme.breakpoints.up('md')]: {
    alignItems: 'center',
    padding: theme.spacing(1.5, 16),
    maxWidth: '1440px',
    margin: '0 auto',
  },

  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(1.5, 32),
  },

  '.bottom-footer-copyright': {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
}))
