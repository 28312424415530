import { SeparatorIcon } from '@components/UI/Icons'
import { IconSearch } from '@components/UI/Icons/VD/General'
import Link from '@components/UI/Link'
import Logo from '@components/UI/Logo'
import { Box } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const StyledTopBarWrapper = styled(Box, {
  name: 'TopBarWrapper',
  shouldForwardProp: prop => prop !== 'scrolled',
})<{ scrolled?: boolean | null }>(({ scrolled, theme }) => ({
  display: 'flex',
  height: '48px',
  width: '100%',
  backgroundColor: theme.palette.background.secondary,
  alignItems: 'center',

  [theme.breakpoints.up('md')]: {
    visibility: scrolled === null ? 'visible' : 'collapse',
    padding: scrolled === null ? theme.spacing(12, 0) : 0,
    height: `${scrolled === null ? '80px' : 0}`,
    opacity: `${scrolled === null ? 1 : 0}`,
    backgroundColor: theme.palette.common.white,
    transition: 'height 0.35s ease-in-out, padding 0.35s ease-in-out, opacity 0.35s ease-in-out',
  },
}))

export const StyledTopBarContainer = styled('section', {
  name: 'TopBarContainer',
})(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(4),
  height: 'inherit',
}))

export const StyledIconSearch = styled(IconSearch)(({ theme }) => ({
  '&.MuiSvgIcon-root': {
    color: theme.palette.background.neutral,
  },
}))

export const StyledPhoneNumber = styled(Link, {
  name: 'StyledPhoneNumber',
  slot: 'Root',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.neutral.main,
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'none',
  },
}))

export const StyledTopBarWrapperLeft = styled('div', {
  name: 'TopBarWrapperLeft',
})(({ theme }) => ({
  width: '144px',
  [theme.breakpoints.up('md')]: {
    width: 'auto',
  },
}))

export const StyledTopBarWrapperRight = styled('div', {
  name: 'TopBarWrapperRight',
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& a': {
    color: theme.palette.neutral.main,
    textDecoration: 'none',
  },
}))

export const StyledTopBarPipe = styled(SeparatorIcon, {
  name: 'TopBarPipe',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    margin: theme.spacing(4),
  },
}))

export const StyledLogo = styled(Logo, {
  name: 'Logo',
})(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}))

export const StyledMobileLogo = styled(Logo, {
  name: 'MobileLogo',
})(({ theme }) => ({
  display: 'block',
  svg: {
    width: '141px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}))
