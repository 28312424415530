import config from '@configs/index'
import Script from 'next/script'

export const TrustPilot = () => {
  return (
    <>
      {config?.trustPilotEnabled && (
        <Script src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
      )}
    </>
  )
}

export default TrustPilot
