import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const BaseLayoutSkeleton = () => {
  return (
    <SkeletonTheme baseColor="transparent" highlightColor={'rgba(0, 0, 0, 0.1)'}>
      <Skeleton width="100%" height="100vh" />
    </SkeletonTheme>
  )
}

export default BaseLayoutSkeleton
