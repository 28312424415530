import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { totalPaginationPagesSelector } from '@features/ui/selector'
import { usePageType } from '@foundation/hooks/usePageType'
import { useSearchParams, usePathname } from 'next/navigation'
import { isPageExcludedFromIndexing } from './helpers'

const useDisableIndexing = (pageTypesToCheck: string[]) => {
  const totalPaginationPages = useSelector(totalPaginationPagesSelector)
  const { pageType: currentPageType } = usePageType()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const [shouldDisableIndexing, setShouldDisableIndexing] = useState<boolean>(false)
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false)

  useEffect(() => {
    setIsPageLoaded(true)
    if (currentPageType && pageTypesToCheck.includes(currentPageType) && isPageLoaded) {
      const pageParamValue = parseInt(searchParams.get('page') || '0')

      if (totalPaginationPages > 0 && pageParamValue > totalPaginationPages) {
        setShouldDisableIndexing(true)

        return
      }

      if (pageParamValue && totalPaginationPages === 0) {
        setShouldDisableIndexing(true)

        return
      }
    }

    if (isPageExcludedFromIndexing(pathname)) {
      setShouldDisableIndexing(true)

      return
    }
  }, [totalPaginationPages, searchParams, pathname, pageTypesToCheck, currentPageType, isPageLoaded])

  return { shouldDisableIndexing }
}

export default useDisableIndexing
