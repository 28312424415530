import { CommonData } from './interfaces'
import { SiteInfo } from '../../../redux/reducers/reducerStateInterface'
import { executeOnce } from '../../../utils/common'
import config from '@configs/config.base'

class TealiumService {
  static amInstance: TealiumService
  store: SiteInfo
  sessionId: string
  commonData: CommonData

  constructor(store: SiteInfo, sessionId: string, commonData: CommonData) {
    this.store = store
    this.sessionId = sessionId
    this.commonData = commonData

    if (TealiumService.amInstance) {
      return TealiumService.amInstance
    }

    this.loadAnalyticScript()

    TealiumService.amInstance = this
    return this
  }

  public loadAnalyticScript = () => {
    return executeOnce(() => {
      const headFragment = document.createDocumentFragment()
      const tealiumTag = document.createElement('script')
      const tealiumTagCookiePage = document.createElement('script')
      const utagProfile = config.name

      const {
        Page_Brand,
        Page_Country,
        Page_Language,
        Page_DeviceType,
        Page_Platform,
        Page_Server,
        Page_Design,
        Order_Currency,
        Production,
        GDPR_Page,
      } = this.commonData
      const utagEnv = Production ? 'prod' : 'qa'

      //const lowercasePageRegionEnv = pageRegionEnv.env.toLowerCase()
      //tealiumTag.id = this.analyticsConfig.gtagId
      tealiumTag.text = `(function(a,b,c,d){
        a='//tags.tiqcdn.com/utag/luxottica/${utagProfile}/${utagEnv}/utag.js';
        b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.defer=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
        })();

        window.tealium_data2track = window.tealium_data2track || []
        window.utag_data = window.utag_data || {};

        function tealiumTag(data){return ({...utag_data, ...data })};
        window.utag_data = tealiumTag({
          Page_Brand: "${Page_Brand}",
          Page_Country: "${Page_Country}",
          Page_Language: "${Page_Language}",
          Page_DeviceType: "${Page_DeviceType}",
          Page_Platform: "${Page_Platform}",
          Page_Server: '${Page_Server}',
          Page_Design: "${Page_Design}",
          Order_Currency: "${Order_Currency}"
        });
        
        `
      tealiumTagCookiePage.text = `
      (function() {
        window.TealiumConsentPrivacyLink = function (){
            const url = '${GDPR_Page}'
            window.open(url, '_blank');
        }
      })()
      `

      headFragment.appendChild(tealiumTag)
      headFragment.appendChild(tealiumTagCookiePage)
      document.head.appendChild(headFragment)
    }, 'dw-gtag-tealium')()
  }
}

export default TealiumService
