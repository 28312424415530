import { styled } from '@mui/material'

export const StyledLogoWrapper = styled('div', {
  name: 'LogoWrapper',
})(({ theme }) => ({
  //! VD styles could start being applied to other brand logos running in VD build
  ...theme.custom.components.logoStyles,
  display: 'flex',

  '> svg': {
    display: 'block',
  },
}))
